import { default as AboutjsonP2xIpu8Gh0Meta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Aboutjson.vue?macro=true";
import { default as Blog_45Full_45ViewFmnfXCnLwEMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Blog-Full-View.vue?macro=true";
import { default as BlogPagekWy8wEPxA2Meta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/BlogPage.vue?macro=true";
import { default as BlogPagePreviewKxqxe06qPKMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/BlogPagePreview.vue?macro=true";
import { default as BootcampSdSJ0YtxleMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Bootcamp.vue?macro=true";
import { default as Community0WCIGZFPrLMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Community.vue?macro=true";
import { default as ContactUsVGI6cGE8ICMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/ContactUs.vue?macro=true";
import { default as _91courseName_93uro13IqQHTMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Course/[courseName].vue?macro=true";
import { default as CourseComparisonxqztllvfA0Meta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/CourseComparison.vue?macro=true";
import { default as Edit_Profile_Personal_DetailsjCGgnEtc0jMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Edit_Profile_Personal_Details.vue?macro=true";
import { default as FAQsaq7UY2GqLAMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/FAQs.vue?macro=true";
import { default as hiretech_46vueRw2pXNeRWJMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/hiretech.vue.vue?macro=true";
import { default as indexXAqVHzT7eeMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/index.vue?macro=true";
import { default as InternshipJoiningFormo3zQ39ety7Meta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/InternshipJoiningForm.vue?macro=true";
import { default as Intership70mVZMKKqJMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Intership.vue?macro=true";
import { default as JobPageyarnlKsesjMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/JobPage.vue?macro=true";
import { default as JobPortalHomedNhoLMFSpRMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/JobPortalHome.vue?macro=true";
import { default as JobPortalProfileVisitorR0RlcBiBm5Meta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/JobPortalProfileVisitor.vue?macro=true";
import { default as JobprofilejobdetailsblIT1RVCqiMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Jobprofilejobdetails.vue?macro=true";
import { default as NewBlog7vZTaPpmZqMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/NewBlog.vue?macro=true";
import { default as NotificationSzlI9SI9UnMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Notification.vue?macro=true";
import { default as policycvZuatJcTcMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/policy.vue?macro=true";
import { default as Profile_45Post_45about_45HeaderGNFojUvWgRMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Profile-Post-about-Header.vue?macro=true";
import { default as ProfilewGAePmyJWYMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Profile.vue?macro=true";
import { default as ProfileTabS8ZpFYgrgHMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/ProfileTab.vue?macro=true";
import { default as ReferAndEarnpBHzpsyWMKMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/ReferAndEarn.vue?macro=true";
import { default as reset_45password5OuMASOwwXMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/reset-password.vue?macro=true";
import { default as ServiceOqZjXsrSCjMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Service.vue?macro=true";
import { default as ServicespageRLdiQpOT6dMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Servicespage.vue?macro=true";
import { default as SponsorshipVJQg1rYYRIMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Sponsorship.vue?macro=true";
import { default as termsT5J4IKGCACMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/terms.vue?macro=true";
import { default as index85JNyFFOVkMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/u/[username]/index.vue?macro=true";
import { default as _91PostId_93MX6TB9lSbYMeta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/u/[username]/Post/[PostId].vue?macro=true";
import { default as UiUXTabsC5ycZtRot9Meta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/UiUXTabs.vue?macro=true";
import { default as viewXNjZo3KPv0Meta } from "/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/view.vue?macro=true";
export default [
  {
    name: AboutjsonP2xIpu8Gh0Meta?.name ?? "Aboutjson",
    path: AboutjsonP2xIpu8Gh0Meta?.path ?? "/Aboutjson",
    meta: AboutjsonP2xIpu8Gh0Meta || {},
    alias: AboutjsonP2xIpu8Gh0Meta?.alias || [],
    redirect: AboutjsonP2xIpu8Gh0Meta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Aboutjson.vue").then(m => m.default || m)
  },
  {
    name: Blog_45Full_45ViewFmnfXCnLwEMeta?.name ?? "Blog-Full-View",
    path: Blog_45Full_45ViewFmnfXCnLwEMeta?.path ?? "/Blog-Full-View",
    meta: Blog_45Full_45ViewFmnfXCnLwEMeta || {},
    alias: Blog_45Full_45ViewFmnfXCnLwEMeta?.alias || [],
    redirect: Blog_45Full_45ViewFmnfXCnLwEMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Blog-Full-View.vue").then(m => m.default || m)
  },
  {
    name: BlogPagekWy8wEPxA2Meta?.name ?? "BlogPage",
    path: BlogPagekWy8wEPxA2Meta?.path ?? "/BlogPage",
    meta: BlogPagekWy8wEPxA2Meta || {},
    alias: BlogPagekWy8wEPxA2Meta?.alias || [],
    redirect: BlogPagekWy8wEPxA2Meta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/BlogPage.vue").then(m => m.default || m)
  },
  {
    name: BlogPagePreviewKxqxe06qPKMeta?.name ?? "BlogPagePreview",
    path: BlogPagePreviewKxqxe06qPKMeta?.path ?? "/BlogPagePreview",
    meta: BlogPagePreviewKxqxe06qPKMeta || {},
    alias: BlogPagePreviewKxqxe06qPKMeta?.alias || [],
    redirect: BlogPagePreviewKxqxe06qPKMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/BlogPagePreview.vue").then(m => m.default || m)
  },
  {
    name: BootcampSdSJ0YtxleMeta?.name ?? "Bootcamp",
    path: BootcampSdSJ0YtxleMeta?.path ?? "/Bootcamp",
    meta: BootcampSdSJ0YtxleMeta || {},
    alias: BootcampSdSJ0YtxleMeta?.alias || [],
    redirect: BootcampSdSJ0YtxleMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Bootcamp.vue").then(m => m.default || m)
  },
  {
    name: Community0WCIGZFPrLMeta?.name ?? "Community",
    path: Community0WCIGZFPrLMeta?.path ?? "/Community",
    meta: Community0WCIGZFPrLMeta || {},
    alias: Community0WCIGZFPrLMeta?.alias || [],
    redirect: Community0WCIGZFPrLMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Community.vue").then(m => m.default || m)
  },
  {
    name: ContactUsVGI6cGE8ICMeta?.name ?? "ContactUs",
    path: ContactUsVGI6cGE8ICMeta?.path ?? "/ContactUs",
    meta: ContactUsVGI6cGE8ICMeta || {},
    alias: ContactUsVGI6cGE8ICMeta?.alias || [],
    redirect: ContactUsVGI6cGE8ICMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/ContactUs.vue").then(m => m.default || m)
  },
  {
    name: _91courseName_93uro13IqQHTMeta?.name ?? "Course-courseName",
    path: _91courseName_93uro13IqQHTMeta?.path ?? "/Course/:courseName()",
    meta: _91courseName_93uro13IqQHTMeta || {},
    alias: _91courseName_93uro13IqQHTMeta?.alias || [],
    redirect: _91courseName_93uro13IqQHTMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Course/[courseName].vue").then(m => m.default || m)
  },
  {
    name: CourseComparisonxqztllvfA0Meta?.name ?? "CourseComparison",
    path: CourseComparisonxqztllvfA0Meta?.path ?? "/CourseComparison",
    meta: CourseComparisonxqztllvfA0Meta || {},
    alias: CourseComparisonxqztllvfA0Meta?.alias || [],
    redirect: CourseComparisonxqztllvfA0Meta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/CourseComparison.vue").then(m => m.default || m)
  },
  {
    name: Edit_Profile_Personal_DetailsjCGgnEtc0jMeta?.name ?? "Edit_Profile_Personal_Details",
    path: Edit_Profile_Personal_DetailsjCGgnEtc0jMeta?.path ?? "/Edit_Profile_Personal_Details",
    meta: Edit_Profile_Personal_DetailsjCGgnEtc0jMeta || {},
    alias: Edit_Profile_Personal_DetailsjCGgnEtc0jMeta?.alias || [],
    redirect: Edit_Profile_Personal_DetailsjCGgnEtc0jMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Edit_Profile_Personal_Details.vue").then(m => m.default || m)
  },
  {
    name: FAQsaq7UY2GqLAMeta?.name ?? "FAQs",
    path: FAQsaq7UY2GqLAMeta?.path ?? "/FAQs",
    meta: FAQsaq7UY2GqLAMeta || {},
    alias: FAQsaq7UY2GqLAMeta?.alias || [],
    redirect: FAQsaq7UY2GqLAMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/FAQs.vue").then(m => m.default || m)
  },
  {
    name: hiretech_46vueRw2pXNeRWJMeta?.name ?? "hiretech.vue",
    path: hiretech_46vueRw2pXNeRWJMeta?.path ?? "/hiretech.vue",
    meta: hiretech_46vueRw2pXNeRWJMeta || {},
    alias: hiretech_46vueRw2pXNeRWJMeta?.alias || [],
    redirect: hiretech_46vueRw2pXNeRWJMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/hiretech.vue.vue").then(m => m.default || m)
  },
  {
    name: indexXAqVHzT7eeMeta?.name ?? "index",
    path: indexXAqVHzT7eeMeta?.path ?? "/",
    meta: indexXAqVHzT7eeMeta || {},
    alias: indexXAqVHzT7eeMeta?.alias || [],
    redirect: indexXAqVHzT7eeMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/index.vue").then(m => m.default || m)
  },
  {
    name: InternshipJoiningFormo3zQ39ety7Meta?.name ?? "InternshipJoiningForm",
    path: InternshipJoiningFormo3zQ39ety7Meta?.path ?? "/InternshipJoiningForm",
    meta: InternshipJoiningFormo3zQ39ety7Meta || {},
    alias: InternshipJoiningFormo3zQ39ety7Meta?.alias || [],
    redirect: InternshipJoiningFormo3zQ39ety7Meta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/InternshipJoiningForm.vue").then(m => m.default || m)
  },
  {
    name: Intership70mVZMKKqJMeta?.name ?? "Intership",
    path: Intership70mVZMKKqJMeta?.path ?? "/Intership",
    meta: Intership70mVZMKKqJMeta || {},
    alias: Intership70mVZMKKqJMeta?.alias || [],
    redirect: Intership70mVZMKKqJMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Intership.vue").then(m => m.default || m)
  },
  {
    name: JobPageyarnlKsesjMeta?.name ?? "JobPage",
    path: JobPageyarnlKsesjMeta?.path ?? "/JobPage",
    meta: JobPageyarnlKsesjMeta || {},
    alias: JobPageyarnlKsesjMeta?.alias || [],
    redirect: JobPageyarnlKsesjMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/JobPage.vue").then(m => m.default || m)
  },
  {
    name: JobPortalHomedNhoLMFSpRMeta?.name ?? "JobPortalHome",
    path: JobPortalHomedNhoLMFSpRMeta?.path ?? "/JobPortalHome",
    meta: JobPortalHomedNhoLMFSpRMeta || {},
    alias: JobPortalHomedNhoLMFSpRMeta?.alias || [],
    redirect: JobPortalHomedNhoLMFSpRMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/JobPortalHome.vue").then(m => m.default || m)
  },
  {
    name: JobPortalProfileVisitorR0RlcBiBm5Meta?.name ?? "JobPortalProfileVisitor",
    path: JobPortalProfileVisitorR0RlcBiBm5Meta?.path ?? "/JobPortalProfileVisitor",
    meta: JobPortalProfileVisitorR0RlcBiBm5Meta || {},
    alias: JobPortalProfileVisitorR0RlcBiBm5Meta?.alias || [],
    redirect: JobPortalProfileVisitorR0RlcBiBm5Meta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/JobPortalProfileVisitor.vue").then(m => m.default || m)
  },
  {
    name: JobprofilejobdetailsblIT1RVCqiMeta?.name ?? "Jobprofilejobdetails",
    path: JobprofilejobdetailsblIT1RVCqiMeta?.path ?? "/Jobprofilejobdetails",
    meta: JobprofilejobdetailsblIT1RVCqiMeta || {},
    alias: JobprofilejobdetailsblIT1RVCqiMeta?.alias || [],
    redirect: JobprofilejobdetailsblIT1RVCqiMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Jobprofilejobdetails.vue").then(m => m.default || m)
  },
  {
    name: NewBlog7vZTaPpmZqMeta?.name ?? "NewBlog",
    path: NewBlog7vZTaPpmZqMeta?.path ?? "/NewBlog",
    meta: NewBlog7vZTaPpmZqMeta || {},
    alias: NewBlog7vZTaPpmZqMeta?.alias || [],
    redirect: NewBlog7vZTaPpmZqMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/NewBlog.vue").then(m => m.default || m)
  },
  {
    name: NotificationSzlI9SI9UnMeta?.name ?? "Notification",
    path: NotificationSzlI9SI9UnMeta?.path ?? "/Notification",
    meta: NotificationSzlI9SI9UnMeta || {},
    alias: NotificationSzlI9SI9UnMeta?.alias || [],
    redirect: NotificationSzlI9SI9UnMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Notification.vue").then(m => m.default || m)
  },
  {
    name: policycvZuatJcTcMeta?.name ?? "policy",
    path: policycvZuatJcTcMeta?.path ?? "/policy",
    meta: policycvZuatJcTcMeta || {},
    alias: policycvZuatJcTcMeta?.alias || [],
    redirect: policycvZuatJcTcMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: Profile_45Post_45about_45HeaderGNFojUvWgRMeta?.name ?? "Profile-Post-about-Header",
    path: Profile_45Post_45about_45HeaderGNFojUvWgRMeta?.path ?? "/Profile-Post-about-Header",
    meta: Profile_45Post_45about_45HeaderGNFojUvWgRMeta || {},
    alias: Profile_45Post_45about_45HeaderGNFojUvWgRMeta?.alias || [],
    redirect: Profile_45Post_45about_45HeaderGNFojUvWgRMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Profile-Post-about-Header.vue").then(m => m.default || m)
  },
  {
    name: ProfilewGAePmyJWYMeta?.name ?? "Profile",
    path: ProfilewGAePmyJWYMeta?.path ?? "/Profile",
    meta: ProfilewGAePmyJWYMeta || {},
    alias: ProfilewGAePmyJWYMeta?.alias || [],
    redirect: ProfilewGAePmyJWYMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Profile.vue").then(m => m.default || m)
  },
  {
    name: ProfileTabS8ZpFYgrgHMeta?.name ?? "ProfileTab",
    path: ProfileTabS8ZpFYgrgHMeta?.path ?? "/ProfileTab",
    meta: ProfileTabS8ZpFYgrgHMeta || {},
    alias: ProfileTabS8ZpFYgrgHMeta?.alias || [],
    redirect: ProfileTabS8ZpFYgrgHMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/ProfileTab.vue").then(m => m.default || m)
  },
  {
    name: ReferAndEarnpBHzpsyWMKMeta?.name ?? "ReferAndEarn",
    path: ReferAndEarnpBHzpsyWMKMeta?.path ?? "/ReferAndEarn",
    meta: ReferAndEarnpBHzpsyWMKMeta || {},
    alias: ReferAndEarnpBHzpsyWMKMeta?.alias || [],
    redirect: ReferAndEarnpBHzpsyWMKMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/ReferAndEarn.vue").then(m => m.default || m)
  },
  {
    name: reset_45password5OuMASOwwXMeta?.name ?? "reset-password",
    path: reset_45password5OuMASOwwXMeta?.path ?? "/reset-password",
    meta: reset_45password5OuMASOwwXMeta || {},
    alias: reset_45password5OuMASOwwXMeta?.alias || [],
    redirect: reset_45password5OuMASOwwXMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: ServiceOqZjXsrSCjMeta?.name ?? "Service",
    path: ServiceOqZjXsrSCjMeta?.path ?? "/Service",
    meta: ServiceOqZjXsrSCjMeta || {},
    alias: ServiceOqZjXsrSCjMeta?.alias || [],
    redirect: ServiceOqZjXsrSCjMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Service.vue").then(m => m.default || m)
  },
  {
    name: ServicespageRLdiQpOT6dMeta?.name ?? "Servicespage",
    path: ServicespageRLdiQpOT6dMeta?.path ?? "/Servicespage",
    meta: ServicespageRLdiQpOT6dMeta || {},
    alias: ServicespageRLdiQpOT6dMeta?.alias || [],
    redirect: ServicespageRLdiQpOT6dMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Servicespage.vue").then(m => m.default || m)
  },
  {
    name: SponsorshipVJQg1rYYRIMeta?.name ?? "Sponsorship",
    path: SponsorshipVJQg1rYYRIMeta?.path ?? "/Sponsorship",
    meta: SponsorshipVJQg1rYYRIMeta || {},
    alias: SponsorshipVJQg1rYYRIMeta?.alias || [],
    redirect: SponsorshipVJQg1rYYRIMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/Sponsorship.vue").then(m => m.default || m)
  },
  {
    name: termsT5J4IKGCACMeta?.name ?? "terms",
    path: termsT5J4IKGCACMeta?.path ?? "/terms",
    meta: termsT5J4IKGCACMeta || {},
    alias: termsT5J4IKGCACMeta?.alias || [],
    redirect: termsT5J4IKGCACMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: index85JNyFFOVkMeta?.name ?? "u-username",
    path: index85JNyFFOVkMeta?.path ?? "/u/:username()",
    meta: index85JNyFFOVkMeta || {},
    alias: index85JNyFFOVkMeta?.alias || [],
    redirect: index85JNyFFOVkMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/u/[username]/index.vue").then(m => m.default || m)
  },
  {
    name: _91PostId_93MX6TB9lSbYMeta?.name ?? "u-username-Post-PostId",
    path: _91PostId_93MX6TB9lSbYMeta?.path ?? "/u/:username()/Post/:PostId()",
    meta: _91PostId_93MX6TB9lSbYMeta || {},
    alias: _91PostId_93MX6TB9lSbYMeta?.alias || [],
    redirect: _91PostId_93MX6TB9lSbYMeta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/u/[username]/Post/[PostId].vue").then(m => m.default || m)
  },
  {
    name: UiUXTabsC5ycZtRot9Meta?.name ?? "UiUXTabs",
    path: UiUXTabsC5ycZtRot9Meta?.path ?? "/UiUXTabs",
    meta: UiUXTabsC5ycZtRot9Meta || {},
    alias: UiUXTabsC5ycZtRot9Meta?.alias || [],
    redirect: UiUXTabsC5ycZtRot9Meta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/UiUXTabs.vue").then(m => m.default || m)
  },
  {
    name: viewXNjZo3KPv0Meta?.name ?? "view",
    path: viewXNjZo3KPv0Meta?.path ?? "/view",
    meta: viewXNjZo3KPv0Meta || {},
    alias: viewXNjZo3KPv0Meta?.alias || [],
    redirect: viewXNjZo3KPv0Meta?.redirect,
    component: () => import("/Users/sudeepmakwana/Documents/BACK_UP_MACOS/diggajcoder/pages/view.vue").then(m => m.default || m)
  }
]